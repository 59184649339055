import React, {useEffect, useRef, useState} from 'react';
import * as RB from 'rebass';

import {
    Link,
} from "react-router-dom";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import './headerBar.css';
import {
    CBadge,
    CButton, CCard, CCardBody, CCardHeader, CCloseButton,
    CCol,
    CContainer,
    CHeader,
    CHeaderBrand,
    CHeaderDivider, CHeaderNav,
    CImage, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle,
    CNavbar,
    CNavbarBrand, CNavbarNav, CNavItem, CNavLink, COffcanvas, COffcanvasBody, COffcanvasHeader, COffcanvasTitle,
    CRow
} from "@coreui/react-pro";
// import ReceiveNotification from "../receiveNotification/receiveNotification";
import {setNotificationHeader, setNotificationMessage, setNotificationType} from "../config/Redux/actions";
import {connect} from "react-redux";
import {cilBell, cilReload, cilTrash, cilX} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {push_notifications, schedule_url} from "../config/config";
import notification from "../notification/Notification";
// import RecentActorsIcon from '@mui/icons-material/RecentActors';


function HeaderBar(props: {
    agent: {};
    setNotificationMessage: (arg0: string) => void;
    setNotificationType: (arg0: string) => void;
    setNotificationHeader: (arg0: string) => void;
}){
    // const [messages, setMessages] = useState({ today: [{
    //         "subject": "Queue closed",
    //         "message": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    //         "read": false,
    //         "date": "2024-04-18T20:22:42Z",
    //         "id": "dgdfg3-3453g-34539",
    //         "past": true
    //     }], yesterday: [], older: [] });
    const [messages, setMessages] = useState({ today: [], yesterday: [], older: [] });
    const [visible, setVisible] = useState(false);
    const [messageVisble, setMessageVisible] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);
    const [activeMessage, setActiveMessage] = useState({});

    // const websocket = useRef(null);
    //
    // function showNotification(message, notificationHeader, notificationType) {
    //     props.setNotificationMessage(message);
    //     props.setNotificationType(notificationType);
    //     props.setNotificationHeader(notificationHeader);
    // }
    //
    // const formatToLocalDateTime = (isoDateString: string): string => {
    //     if (isoDateString === "") {
    //         return "N/A";
    //     }
    //     // Append 'Z' to treat as UTC if no timezone info is present
    //     const date = new Date(isoDateString.includes('Z') || isoDateString.includes('+') ? isoDateString : isoDateString + 'Z');
    //     const options: Intl.DateTimeFormatOptions = {
    //         day: '2-digit',
    //         month: '2-digit',
    //         year: 'numeric',
    //         hour: '2-digit',
    //         minute: '2-digit',
    //         hour12: false,
    //         timeZoneName: 'short' // Optional: Shows the timezone abbreviation
    //     };
    //     return date.toLocaleString(undefined, options); // 'undefined' uses the user's default locale
    // };
    //
    //
    // const categorizeDate = (date) => {
    //     const messageDate = new Date(date);
    //     const today = new Date();
    //     const yesterday = new Date(today);
    //     yesterday.setDate(yesterday.getDate() - 1);
    //
    //     if (messageDate.toDateString() === today.toDateString()) {
    //         return 'today';
    //     } else if (messageDate.toDateString() === yesterday.toDateString()) {
    //         return 'yesterday';
    //     } else {
    //         return 'older';
    //     }
    // };
    //
    // const viewNotification = (message) =>{
    //     console.log(message)
    //     setActiveMessage(message);
    //     setMessageVisible(true);
    //     message.read = true;
    //
    //     if (websocket.current) {
    //         websocket.current.send(JSON.stringify({
    //             action: 'read',
    //             id: `${message.id}#${props.agent.username}`
    //         }));
    //     }
    //     setUnreadCount(prev => prev - 1);
    // }
    //
    // const deleteNotification = (messageId, category, read) => {
    //     if(!read){
    //         console.log("READ STATUS", read)
    //         setUnreadCount(prev => prev - 1);
    //     }
    //     setMessages(prevMessages => {
    //         // Create a new object to avoid direct state mutation
    //         const newMessages = {...prevMessages};
    //         // Filter out the message to delete
    //         newMessages[category] = newMessages[category].filter(msg => msg.id !== messageId);
    //         return newMessages;
    //     });
    //
    //     if (websocket.current) {
    //         websocket.current.send(JSON.stringify({
    //             action: 'delete',
    //             id: `${messageId}#${props.agent.username}`
    //         }));
    //     }
    // }
    //
    //
    // useEffect(() => {
    //
    //     if(props.agent.id === ''){
    //         return
    //     }
    //
    //     console.log("AGENT DETAILS **", props.agent)
    //     function connect() {
    //         // setUnreadCount(0);
    //         // setMessages({ today: [], yesterday: [], older: [] });
    //         websocket.current = new WebSocket(`${push_notifications}?userId=${props.agent.id}`);
    //
    //         websocket.current.onopen = () => {
    //             console.log('WebSocket Connected');
    //         };
    //
    //         websocket.current.onclose = event => {
    //             console.log('Notification WebSocket Disconnected. Attempting to reconnect...', event.reason);
    //             setTimeout(() => {
    //                 setMessages({ today: [], yesterday: [], older: [] });
    //                 setUnreadCount(0);
    //                 connect();
    //             }, 1000);
    //         };
    //
    //         websocket.current.onmessage = event => {
    //             const newMessage = JSON.parse(event.data);
    //             const category = categorizeDate(newMessage.date);
    //             console.log("NEW MESSAGE CAT", newMessage.date, category);
    //
    //             setMessages(prev => {
    //                 const sortedMessages = [...prev[category], newMessage].sort((a, b) => new Date(b.date) - new Date(a.date));
    //                 return {
    //                     ...prev,
    //                     [category]: sortedMessages
    //                 };
    //             });
    //
    //             if (!newMessage.read) {
    //                 setUnreadCount(prev => prev + 1);
    //                 if (!newMessage.past) {
    //                     showNotification(`<div>${newMessage.message}</div>`, `<div>${newMessage.subject}</div>`, "Success");
    //                 }
    //             }
    //         };
    //
    //         websocket.current.onerror = error => {
    //             console.error('WebSocket Error:', error);
    //             setMessages({ today: [], yesterday: [], older: [] })
    //             setUnreadCount(0)
    //         };
    //     }
    //
    //     connect();
    //
    //     return () => {
    //         if (websocket.current) {
    //             websocket.current.close();
    //         }
    //     };
    // }, [props.agent.id]);

    return (
        <>
            {/*<CModal*/}
            {/*    visible={messageVisble}*/}
            {/*    onClose={() => setMessageVisible(false)}*/}
            {/*    aria-labelledby="LiveDemoExampleLabel"*/}
            {/*>*/}
            {/*    <CModalHeader onClose={() => setVisible(false)}>*/}
            {/*        <CModalTitle id="LiveDemoExampleLabel">{activeMessage.subject}</CModalTitle>*/}
            {/*    </CModalHeader>*/}
            {/*    <CModalBody>*/}
            {/*        <p>{activeMessage.message}</p>*/}
            {/*    </CModalBody>*/}
            {/*</CModal>*/}
        {/*<COffcanvas backdrop={false} placement="right" visible={visible} onHide={() => setVisible(false)}>*/}
        {/*    <COffcanvasHeader id="offCanvasHeader">*/}
        {/*        <CCloseButton placement="right" className="text-reset" onClick={() => setVisible(false)} />*/}
        {/*    </COffcanvasHeader>*/}
        {/*    <COffcanvasBody id="offCanvasBody">*/}
        {/*        {['today', 'yesterday', 'older'].map((category) => (*/}
        {/*            messages[category].length > 0 && (*/}
        {/*                <>*/}
        {/*                <CCard key={category} id="notifiactionDateCard">*/}
        {/*                    <CCardHeader id="notificationDateCardHeader">*/}
        {/*                        <strong>{category.charAt(0).toUpperCase() + category.slice(1)}</strong>*/}
        {/*                    </CCardHeader>*/}
        {/*                    <CCardBody id="notificationDateCardBody">*/}
        {/*                        {messages[category].map((message, index) => (*/}
        {/*                            <div key={index} className={`border-start border-start-4 py-1 px-3 ${message.read ? "border-start-secondary" : "border-start-success"} border-bottom border-bottom-1 messageContainer`} onClick={()=>viewNotification(message)}>*/}
        {/*                                <div className="notificationHeader">*/}
        {/*                                    <CButton color="link" variant="outline" onClick={(e) => {*/}
        {/*                                        e.stopPropagation(); // Prevent onClick for viewing from being triggered*/}
        {/*                                        deleteNotification(message.id, category, message.read);*/}
        {/*                                    }}>*/}
        {/*                                        <CIcon icon={cilX}></CIcon>*/}
        {/*                                    </CButton>*/}
        {/*                                </div>*/}
        {/*                                <div>*/}
        {/*                                    <strong>{message.subject}</strong>*/}
        {/*                                    <p className="notificationMessageBody">{message.message}</p>*/}
        {/*                                </div>*/}
        {/*                                <div className="notificationFooter">*/}
        {/*                                    <span>{formatToLocalDateTime(message.date)}</span>*/}
        {/*                                </div>*/}
        {/*                            </div>*/}
        {/*                        ))}*/}
        {/*                    </CCardBody>*/}
        {/*                </CCard>*/}
        {/*                /!*<br/>*!/*/}
        {/*                </>*/}
        {/*            )*/}
        {/*        ))}*/}
        {/*    </COffcanvasBody>*/}
        {/*</COffcanvas>*/}
        <CHeader position="sticky" id="headerBar">
            <CContainer fluid>
                <CHeaderBrand>
                    <div className="scheduleIconBox">
                        {/*<a href={schedule_url} target="_blank">*/}
                        {/*    <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" fill="currentColor" role="img" width="inherit" height="8%" aria-label="[object Object]" aria-hidden="false"><path d="M8 1v2h8V1h2v2h1a2 2 0 012 2v15a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h1V1h2zm11 7.007H5V20h14V8.007zm-8.669 2.472c.117-.003.23.06.34.19l.066.087 2.93 3.515 2.565-3.078 1.536 1.28-3.682 4.419c-.136.195-.274.294-.414.297-.117.002-.231-.062-.344-.194l-.067-.087-2.928-3.513-2.565 3.079-1.536-1.28 3.68-4.418c.14-.194.279-.293.42-.297z" fill="#FFF"></path></svg>*/}
                        {/*</a>*/}
                        <span color="white">WallBoard</span>
                    </div>
                </CHeaderBrand>
                {/*<CNavbarNav id="notification">*/}
                {/*    <CNavItem href="#" onClick={() => setVisible(true)} className="position-relative" disabled={(messages.today.length + messages.yesterday.length + messages.older.length) === 0}>*/}
                {/*        /!*<CNavLink href="#" onClick={() => setVisible(true)}>*!/*/}
                {/*            <CIcon icon={cilBell} size="lg"/>*/}
                {/*        {unreadCount > 0 &&*/}
                {/*            <CBadge color="danger" position="top-end" shape="rounded-circle">*/}
                {/*                {unreadCount} <span className="visually-hidden">unread messages</span>*/}
                {/*            </CBadge>*/}
                {/*        }*/}
                {/*        /!*</CNavLink>*!/*/}
                {/*    </CNavItem>*/}
                {/*</CNavbarNav >*/}
            </CContainer>
        </CHeader>
        </>
    );
}

const mapDispatchToProps = {
    setNotificationMessage,
    setNotificationHeader,
    setNotificationType
};


export default connect(null, mapDispatchToProps)(HeaderBar)

import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { CToast, CToastBody, CToaster, CToastHeader } from "@coreui/react-pro";
import { setNotificationMessage } from "../config/Redux/actions";

const Notification = ({ notificationMessage, notificationHeader, notificationType }) => {
    const showToast = notificationMessage !== '';
    const dispatch = useDispatch();

    const resetNotification = () => {
        dispatch(setNotificationMessage(""));
        console.log("reset");
    };

    // Play a sound when toast is visible
    useEffect(() => {
        if (showToast) {
            // Play a chime sound from the .wav file located in public/sounds
            const audio = new Audio('/sounds/mixkit-digital-quick-tone-2866.wav'); // Update with the correct path to your .wav file
            audio.play().catch(err => console.error('Audio playback failed', err));
        }
    }, [showToast]);

    let color = '';
    switch (notificationType) {
        case "Error":
            color = "#e55353";
            break;
        case "Success":
            color = "#2eb85c";
            break;
        case "Warning":
            color = "#f9b115";
            break;
        default:
            color = "#39f";
    }

    return (
        <>
            <CToaster placement="top-end">
                <CToast autohide={true} visible={showToast} onClose={resetNotification}>
                    <CToastHeader closeButton>
                        <svg
                            className="rounded me-2"
                            width="20"
                            height="20"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="xMidYMid slice"
                            focusable="false"
                            role="img"
                        >
                            <rect width="100%" height="100%" fill={color}></rect>
                        </svg>
                        <div className="fw-bold me-auto" dangerouslySetInnerHTML={{ __html: notificationHeader }} />
                    </CToastHeader>
                    <CToastBody dangerouslySetInnerHTML={{ __html: notificationMessage }} />
                </CToast>
            </CToaster>

            {/* Audio element for sound notification */}
            <audio id="notificationChime" src="/sounds/notification-chime.wav" preload="auto"></audio>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        notificationMessage: state.notificationMessage,
        notificationHeader: state.notificationHeader,
        notificationType: state.notificationType
    };
};

export default connect(mapStateToProps)(Notification);